<template>
  <div :class="{ 'max-width-650': $vuetify.breakpoint.mdAndUp }">
    <v-card v-if="hoursOnDay.length === 0 || isAddNew">
      <v-card-title class="pt-3" :style="`height: 50px; font-size: 1.1rem; background-color: ${systemDetails.themecolor}; color: ${systemDetails.textcolor}`">
        {{ hourObj._id ? this.$t('editHours') : this.$t('addHours') }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0" style="max-height: 71vh; overflow: auto">
        <form-data :references.sync="formReferences" :model="hourObj" ref="hourReference"></form-data>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions style="height: 50px" class="justify-center">
        <v-btn small class="mr-2" color="success" @click="saveUpdateHandler" :disabled="isReadOnly" :loading="loading">
          {{ hourObj._id ? this.$t('update') : this.$t('save') }}
        </v-btn>
        <v-btn small color="error" @click="closeLogForm"> {{ this.$t('close') }} </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CONSTANTS from '@/assets/json/constants.json'
// import { decrypt } from '../../../utils/crypto'
export default {
  props: ['value', 'hoursOnDay', 'worktypes', 'users', 'tasks', 'currentProjects', 'timerFormSettings', 'timecodes', 'storedProject', 'storedCustomer'],
  data () {
    return {
      MODULE: 'hours',
      isAddNew: false,
      isReadOnly: false,
      logObj: {},
      listOfTasks: [],
      activities: [],
      activeUser: {},
      listOfCustomers: [],
      listOfProjects: [],
      customerSearchLoading: false,
      projectSearchLoading: false,
      canShowHours: true,
      isTypeAbsence: false,
      loading: false,
      hourObj: {},
      ismounted: true,
      searchItem: '',
      skip: 0
    }
  },
  components: {
    'form-data': () => import('@/components/FormTemplate')
  },
  computed: {
    ...mapGetters(['formType', 'userDetails', 'systemDetails', 'getTimerSettings']),
    formReferences () {
      return {
        properties: [{
          model: 'customerid',
          live_search_model: 'customerSearch',
          type: this.getTimerSettings && this.getTimerSettings.filteraccountsbyassignee ? this.formType.AUTO_COMPLETE : this.formType.AUTO_COMPLETE_LIVE,
          // type: this.formType.AUTO_COMPLETE_LIVE,
          rules: this.$_requiredValidation,
          items: this.listOfCustomers,
          select_text: 'name',
          select_value: '_id',
          // disabled: Boolean(this.hourObj._id),
          label: this.$t('customers'),
          class: 'xs12',
          keydown: this.preventSpecialCharacters,
          change: this.customerChangeHandler,
          // change: () => this.makeListEmpty('listOfProjects'),
          loading: this.customerSearchLoading,
          is_visible: this.timerFormSettings.customer && !this.isTypeAbsence,
          ref: 'ref_customerid'
        }, {
          model: 'projectid',
          live_search_model: 'projectSearch',
          // type: this.formType.AUTO_COMPLETE_LIVE,
          type: this.getTimerSettings && this.getTimerSettings.filterprojectsbyassignee ? this.formType.AUTO_COMPLETE : this.formType.AUTO_COMPLETE_LIVE,
          rules: this.$_requiredValidation,
          items: this.listOfProjects,
          select_text: 'projectName',
          select_value: '_id',
          disabled: this.isReadOnly,
          label: this.$t('projects'),
          keydown: this.preventSpecialCharacters,
          class: 'xs12',
          is_visible: this.timerFormSettings.project && !this.isTypeAbsence,
          ref: 'ref_projectid'
        }, {
          model: 'worktypeid',
          type: this.formType.AUTO_COMPLETE,
          rules: this.$_requiredValidation,
          items: this.worktypes,
          is_list: false,
          click: (search) => false,
          disabled: this.isReadOnly,
          select_text: 'name',
          select_value: '_id',
          label: this.$t('worktype'),
          class: 'xs12',
          is_visible: this.timerFormSettings.worktype,
          change: this.checkForAbsenceType,
          ref: 'ref_worktypeid'
        }, {
          model: 'start_date',
          type: this.formType.DATEPICKER,
          rules: this.$_requiredValidation,
          picker: 'datePickerStart',
          menu: 'dateMenuStart',
          color: 'primary lighten-1',
          label: '',
          max: 'datePickerEnd',
          disabled: this.isReadOnly,
          class: 'sm6 xs12',
          change: () => this.updateCanShowHours(),
          is_visible: this.isTypeAbsence,
          ref: 'ref_start_date'
        }, {
          model: 'end_date',
          type: this.formType.DATEPICKER,
          rules: this.$_requiredValidation,
          picker: 'datePickerEnd',
          menu: 'dateMenuEnd',
          color: 'primary lighten-1',
          label: '',
          min: 'datePickerStart',
          disabled: this.isReadOnly,
          class: 'sm6 xs12',
          change: () => this.updateCanShowHours(),
          is_visible: this.isTypeAbsence,
          ref: 'ref_end_date'
        }, {
          model: 'date',
          type: this.formType.DATEPICKER,
          rules: this.$_requiredValidation,
          picker: 'datePicker',
          menu: 'dateMenu',
          color: 'primary lighten-1',
          label: '',
          disabled: this.isReadOnly,
          class: 'sm6 xs12',
          is_visible: !this.isTypeAbsence,
          ref: 'ref_date'
        }, {
          model: 'hours',
          type: this.formType.TEXT,
          rules: this.$_HourValidation,
          label: this.$t('hours'),
          class: 'sm6 xs12',
          // change: this.setInvoiceTo,
          disabled: this.isReadOnly,
          is_visible: !this.timerFormSettings.start_end_time && !this.isTypeAbsence && this.canShowHours,
          ref: 'ref_hours'
        }, {
          model: 'starttime',
          max: 'endtime',
          type: this.formType.TIMEPICKER,
          rules: this.$_requiredValidation,
          menu: 'startTimeMenu',
          pickerModel: 'pickerstart',
          label: this.$t('startTime'),
          disabled: this.isReadOnly,
          class: 'xs12 sm6',
          is_visible: this.timerFormSettings.start_end_time && !this.isTypeAbsence,
          ref: 'ref_starttime'
        }, {
          model: 'endtime',
          min: 'starttime',
          type: this.formType.TIMEPICKER,
          pickerModel: 'pickerend',
          rules: this.$_requiredValidation,
          menu: 'endTimeMenu',
          label: this.$t('endTime'),
          disabled: this.isReadOnly,
          class: 'xs12 sm6',
          is_visible: this.timerFormSettings.start_end_time && !this.isTypeAbsence,
          ref: 'ref_endtime'
        }, {
          model: 'hours',
          type: this.formType.TEXT,
          rules: this.$_HourValidation,
          label: this.$t('hours'),
          class: 'sm6 xs12',
          disabled: true,
          is_visible: this.timerFormSettings.start_end_time && !this.isTypeAbsence,
          ref: 'ref_hours'
        }, { // Hour field for absence type
          model: 'hours',
          type: this.formType.TEXT,
          rules: this.$_HourValidation,
          label: this.$t('hours'),
          disabled: this.isReadOnly,
          class: 'sm6 xs12',
          is_visible: this.canShowHours && this.isTypeAbsence,
          ref: 'ref_hours'
        }, {
          model: 'toinvoice',
          type: this.formType.TEXT,
          rules: this.$_toInvoiceValidation,
          label: this.$t('invoiceTo'),
          class: 'sm6 xs12',
          disabled: this.isReadOnly,
          is_visible: !!((this.timerFormSettings.to_invoice && !this.isTypeAbsence) && (this.getTimerSettings.setinvoiceonlybyadmin ? this.userDetails.isadmin : true)),
          ref: 'ref_toinvoice'
        }, {
          model: 'overtime50',
          type: this.formType.TEXT,
          rules: this.hourObj.overtime50 ? this.$_HourValidation : [],
          label: `50% ${this.$t('overtime')}`,
          class: `sm6 ${this.timerFormSettings.overtime_100 ? 'xs6' : 'xs12'}`, /* 'sm6 xs12' */
          disabled: this.isReadOnly,
          is_visible: this.timerFormSettings.overtime_50 && !this.isTypeAbsence,
          ref: 'ref_overtime50'
        }, {
          model: 'overtime100',
          type: this.formType.TEXT,
          rules: this.hourObj.overtime100 ? this.$_HourValidation : [],
          label: `100% ${this.$t('overtime')}`,
          class: `sm6 ${this.timerFormSettings.overtime_50 ? 'xs6' : 'xs12'}`, /* 'sm6 xs12' */
          disabled: this.isReadOnly,
          is_visible: this.timerFormSettings.overtime_100 && !this.isTypeAbsence,
          ref: 'ref_overtime100'
        }, {
          model: 'breakhr',
          type: this.formType.TEXT,
          rules: this.hourObj.breakhr ? this.$_HourValidation : [],
          label: this.$t('breakHours'),
          class: 'sm6 xs12',
          disabled: this.isReadOnly,
          is_visible: this.timerFormSettings.breakhrs && !this.isTypeAbsence,
          ref: 'ref_breakhr'
        }, {
          model: 'timecode',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          label: this.$t('timeCode'),
          class: 'xs12 sm6',
          items: this.timecodes,
          select_text: 'code',
          select_value: '_id',
          disabled: this.isReadOnly,
          is_visible: this.timerFormSettings.timecode && !this.isTypeAbsence,
          ref: 'ref_timecode'
        }, {
          model: 'taskid',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          items: this.listOfTasks,
          is_list: false,
          click: (search) => false,
          disabled: this.isReadOnly,
          hasLiveSearch: true,
          select_text: 'name',
          select_value: '_id',
          label: this.$t('task'),
          class: (this.timerFormSettings.start_end_time && !this.timerFormSettings.timecode) || (!this.timerFormSettings.start_end_time && this.timerFormSettings.timecode) ? 'xs6' : 'xs12',
          is_visible: this.timerFormSettings.task && !this.isTypeAbsence,
          ref: 'ref_taskid'
        }, {
          model: 'comments',
          type: this.formType.TEXTAREA,
          rules: [],
          disabled: this.isReadOnly,
          label: this.$t('comments'),
          class: 'xs12',
          rows: 3,
          is_visible: this.timerFormSettings.comments,
          ref: 'ref_comments'
        }, {
          model: 'internalcomments',
          type: this.formType.TEXTAREA,
          rules: [],
          disabled: this.isReadOnly,
          label: this.$t('internalComments'),
          class: 'xs12',
          rows: 3,
          is_visible: this.timerFormSettings.internal_comments && !this.isTypeAbsence,
          ref: 'ref_internalcomments'
        }, {
          model: 'notapprovedcomment',
          type: this.formType.TEXTAREA,
          rules: [],
          is_show: this.hourObj.state === CONSTANTS.NOT_APPROVED,
          disabled: true,
          label: this.$t('notApproved'),
          class: 'xs12',
          rows: 3,
          ref: 'ref_notapprovedcomment'
        }]
      }
    }
  },
  mounted () {
    /* need to implement link when live search field value set get the name of the item also inorder to set the value default in the list */
    this.hourObj.start_date = this.hourObj.start_date ? this.$formatter.formatDate(this.hourObj.start_date, 'DD.MM.YYYYTHH.mm.ss', this.userDetails.dateformat) : this.$formatter.getCurrentDate()
    this.hourObj.end_date = this.hourObj.end_date ? this.$formatter.formatDate(this.hourObj.end_date, 'DD.MM.YYYYTHH.mm.ss', this.userDetails.dateformat) : this.$formatter.getCurrentDate()
    // this.listOfProjects = this.$formatter.cloneVariable(this.currentProjects)
    // if (!this.hourObj._id) {
    //   if (this.storedCustomer.length > 0) this.listOfCustomers = this.storedCustomer
    //   if (this.storedProject.length > 0) this.listOfProjects = this.storedProject
    // }
    if (this.hourObj.state === CONSTANTS.APPROVED || this.hourObj.state === CONSTANTS.DELIVERED) this.isReadOnly = true
    if (this.hourObj.projectid) this.getTasksByProject(this.hourObj.projectid, this.skip, this.searchItem)
    if (this.timerFormSettings && this.timerFormSettings.customer && this.hourObj.customerid) {
      this.listOfCustomers.push({ _id: this.hourObj.customerid, name: this.hourObj.customer_name || '' })
    }

    // worktype default set
    if (this.hourObj._id) {
      this.isTypeAbsence = Boolean(this.hourObj.isabsence)
      if (this.getTimerSettings.setinvoiceonlybyadmin ? this.userDetails.isadmin : true) {
        this.hourObj.toinvoice = this.$formatter.replaceDotWithComma(this.hourObj.toinvoice)
      }
    } else if (!this.hourObj._id) {
      if (this.timerFormSettings.worktype) {
        const worktype = this.worktypes.find(x => x.isdefault)
        if (worktype) {
          this.hourObj.worktypeid = worktype._id
          this.hourObj.type = (worktype.isabsence) ? this.NON_WORKING : this.WORKING
          this.isTypeAbsence = !!worktype.isabsence
        }
      } else {
        this.isTypeAbsence = false
        this.hourObj.type = this.WORKING
      }
    }

    this.$root.$on('taskProjectId', (skip, searchItem) => {
      this.getTasksByProject(this.hourObj.projectid, skip, searchItem)
    })

    // To show fields based on hour type
    if (this.hourObj.type === CONSTANTS.NON_WORKING) {
      this.setAllowedDates()
      this.hourObj.start_date === this.hourObj.end_date ? this.canShowHours = true : this.canShowHours = false
      this.isTypeAbsence = true
    }
    // if (this.hourObj.type === this.NON_WORKING) this.hourObj.start_date === this.hourObj.end_date ? this.canShowHours = true : this.canShowHours = false
    // activities default set
    this.listOfTasks = this.tasks.filter(x => x.projectid === this.hourObj.projectid)
    const tasks = this.listOfTasks.find(x => x.isdefault)
    if (tasks) this.hourObj.taskid = tasks._id
    const timecode = this.timecodes.find(x => x.isdefault)
    if (timecode && this.timerFormSettings && this.timerFormSettings.timecode && !this.hourObj._id) this.hourObj.timecode = timecode._id
    // read active user
    // this.activeUser = this.$cookie.get(process.env.VUE_APP_USER) ? decrypt(JSON.parse(this.$cookie.get(process.env.VUE_APP_USER))) : {}
    this.activeUser = this.$formatter.cloneVariable(this.users.find(user => user._id === this.userDetails._id))
    this.activeUser.normalworkinghours = (this.users.find(user => user._id === this.activeUser._id)).normalworkinghours
    if (this.activeUser._id) this.activeUser._id = parseInt(this.activeUser._id)
    // check is so task and single entry then open the modal form
    if (this.hoursOnDay && this.hoursOnDay.length === 1 && this.hoursOnDay[0].isSoTask) {
      const soTask = this.hoursOnDay[0]
      this.isAddNew = true
      this.$eventBus.$emit('editSingleEntry', { id: soTask.id, isSoTask: soTask.isSoTask })
    }
    this.listOfProjects = this.hourObj._id ? this.$formatter.cloneVariable(this.hourObj.projectsListOnEdit) : []
    // this.$eventBus.$on('updateProjectsList', (data) => {
    //   this.listOfProjects = this.$formatter.cloneVariable(data)
    // })
    this.$store.dispatch('getTimerSettings')
      .then(async () => {
        // if (this.getTimerSettings.filteraccountsbyassignee) await this.loadCustomerData()
        this.prefillCustomerAndProject()
        // if (this.getTimerSettings.filterprojectsbyassignee) await this.loadProjectData()
      })
  },
  watch: {
    value: {
      handler () {
        if (this.ismounted) this.hourObj = this.value
        this.ismounted = false
      },
      deep: true,
      immediate: true
    },
    'hourObj.datePicker' (val) {
      if (val) {
        this.hourObj.date = this.$formatter.formatDate(val, 'YYYY-MM-DD', this.userDetails.dateformat)
      }
    },
    'hourObj.datePickerStart' (val) {
      if (val) {
        this.hourObj.start_date = this.$formatter.formatDate(val, 'YYYY-MM-DD', this.userDetails.dateformat)
        if (this.hourObj.start_date === this.hourObj.end_date) this.canShowHours = true
        else this.canShowHours = false
      }
    },
    'hourObj.datePickerEnd' (val) {
      if (val) {
        this.hourObj.end_date = this.$formatter.formatDate(val, 'YYYY-MM-DD', this.userDetails.dateformat)
        if (this.hourObj.start_date === this.hourObj.end_date) this.canShowHours = true
        else this.canShowHours = false
      }
    },
    // 'hourObj.projectid' (val) {
    //   if (val) this.getTasksByProject(val)
    //   else this.listOfTasks = []
    // },
    'hourObj.customerSearch' (val) {
      if (val === undefined) return
      if (val) this.loadCustomerData()
      else this.listOfCustomers = []
    },
    'hourObj.projectSearch' (val) {
      if (val === undefined) return
      if (val && !val.includes('-')) this.loadProjectData(val)
    },
    // set amount when start & end time change
    'hourObj.starttime' (val) {
      if (val) this.setHours(val, this.hourObj.endtime)
    },
    'hourObj.endtime': {
      handler () {
        if (this.hourObj.endtime) this.setHours(this.hourObj.starttime, this.hourObj.endtime)
        else {
          this.hourObj.hours = '0,00'
          if (this.getTimerSettings.setinvoiceonlybyadmin ? this.userDetails.isadmin : true) this.hourObj.toinvoice = '0,00'
        }
      }
    }
  },
  methods: {
    async prefillCustomerAndProject () {
      let timerFormPreference = localStorage.getItem(`${process.env.VUE_APP_STORE_NAME}_${this.userDetails.tenantname}_timerFormPreference`)
      timerFormPreference = timerFormPreference ? JSON.parse(timerFormPreference) : null
      if (this.getTimerSettings.filteraccountsbyassignee) await this.loadCustomerData()
      if (timerFormPreference && !this.hourObj._id) {
        if (timerFormPreference.customer) {
          let customerArray = []
          if (!this.getTimerSettings.filteraccountsbyassignee && this.timerFormSettings.customer) {
            await this.loadCustomerData({ searchterm: timerFormPreference.customer.name })
              .then((data) => {
                customerArray = data
              })
          } else {
            customerArray = this.listOfCustomers
          }
          if (customerArray.map((customer) => customer._id).includes(timerFormPreference.customer._id)) this.hourObj.customerid = timerFormPreference.customer._id
        }
      }
      // if (this.getTimerSettings.filterprojectsbyassignee || timerFormPreference) await this.loadProjectData()
      if (this.getTimerSettings.filterprojectsbyassignee) {
        await this.loadProjectData().then(() => {
          if (this.hourObj._id && this.timerFormSettings && this.timerFormSettings.project && this.hourObj.projectid && this.listOfProjects && !this.listOfProjects.find(x => x._id === this.hourObj.projectid)) {
            this.listOfProjects.push({ _id: this.hourObj.projectid, projectName: this.hourObj.project_name && this.hourObj.project_number ? `${this.hourObj.project_number} - ${this.hourObj.project_name}` : this.hourObj.project_name, disabled: true })
          }
        })
      } else if (timerFormPreference && !this.hourObj._id) await this.customerChangeHandler()
      if ((timerFormPreference && this.timerFormSettings.project && timerFormPreference.project && !this.hourObj._id) && this.listOfProjects.map((project) => project._id).includes(timerFormPreference.project._id)) {
        const hourObj = this.$formatter.cloneVariable(this.hourObj)
        this.hourObj = Object.assign({}, { ...hourObj, projectid: timerFormPreference.project._id })
      }
    },
    deleteHourHandler () {
      const ids = [this.hourObj._id]
      const payload = { ids, url: `${this.MODULE}/delete_multiple`, module: this.MODULE }
      this.$store.commit('showDeleteDialog', payload)
    },
    // getTasksByProject (projectId) {
    //   this.$api.execute('get', `tasks/get_by_project/${projectId}`).then(response => {
    //     // this.listOfTasks = response.data
    //     // const userAssignedTasks = response.data.filter(x => x.assigned_to === this.userDetails._id)
    //     this.listOfTasks = this.$formatter.cloneVariable(response.data)
    //   })
    // },
    getTasksByProject (projectId, skip, searchItem) {
      const limit = 30
      this.$api.execute('get', `tasks/get_by_project/${projectId}/${skip}/${limit}?searchTerm=${searchItem}`).then(response => {
        response.data.data.forEach(task => {
          // this.listOfTasks.push(task.data)
          this.listOfTasks.push({ name: task.data.name, _id: task._id })
          // const userAssignedTasks = this.listOfTasks.filter(x => x.assigned_to === this.userId)
        })
      })
    },
    updateCanShowHours () {
      this.canShowHours = this.hourObj.start_date === this.hourObj.end_date
    },
    saveUpdateHandler () {
      if (this.$refs.hourReference.$refs.validateForm.validate()) {
        this.loading = true
        const transformData = { date: { list: ['date', 'start_date', 'end_date'], from: this.userDetails.dateformat, to: 'YYYY-MM-DDT12:00:00' } }
        let model = this.$formatter.formatModel(this.hourObj, transformData)
        if (this.timerFormSettings.project || this.timerFormSettings.customer) {
          window.localStorage.setItem('PDCRMPWA_timerpreset', JSON.stringify(
            {
              project: this.timerFormSettings.project ? this.listOfProjects : [],
              customer: this.timerFormSettings.customer ? this.listOfCustomers : [],
              projectid: this.timerFormSettings.project ? model.projectid : null,
              customerid: this.timerFormSettings.customer ? model.customerid : null
            }
          ))
        }
        /* if start_end_time based form - this is new changes in time management */
        if (this.isTypeAbsence) {
          // model.start_date = this.$formatter.formatDate(model.start_date, 'DD.MM.YYYY HH:mm', 'YYYY-MM-DDTHH:mm:ss')
          // model.end_date = this.$formatter.formatDate(model.end_date, 'DD.MM.YYYY HH:mm', 'YYYY-MM-DDTHH:mm:ss')
          model.hours = model.start_date === model.end_date ? model.hours : this.activeUser.normalworkinghours
          model.hourbank = model.hourbank ? model.hourbank : 0
          if (this.getTimerSettings.setinvoiceonlybyadmin ? this.userDetails.isadmin : true) model.toinvoice = null
        } else {
          const date = this.$formatter.formatDate(model.date, 'YYYY-MM-DD', this.userDetails.dateformat)
          if (this.timerFormSettings.start_end_time) { // if timerFormSettings.start_end_time is enabled we need to attach start and end time with start_date and end_date
            model.start_date = this.$formatter.formatDate(`${date} ${model.starttime}`, `${this.userDetails.dateformat} HH:mm`, 'YYYY-MM-DDTHH:mm:ss')
            model.end_date = this.$formatter.formatDate(`${date} ${model.endtime}`, `${this.userDetails.dateformat} HH:mm`, 'YYYY-MM-DDTHH:mm:ss')
          } else {
            model.start_date = this.$formatter.formatDate(`${date}`, `${this.userDetails.dateformat} HH:mm`, 'YYYY-MM-DDTHH:mm:ss')
            model.end_date = this.$formatter.formatDate(`${date} 23:59:59`, `${this.userDetails.dateformat} HH:mm:ss`, 'YYYY-MM-DDTHH:mm:ss')
          }
        }
        /* start_end_time based ends  */
        const transformNumber = { number: { list: ['hours'], format: 'replaceCommaWithDot' } }
        if (this.getTimerSettings.setinvoiceonlybyadmin ? this.userDetails.isadmin : true) transformNumber.number.list.push('toinvoice')
        model = this.$formatter.formatModel(model, transformNumber)
        if (transformNumber.number.list.includes('toinvoice')) model.toinvoice = model.toinvoice || 0
        model.state = model._id ? model.state !== CONSTANTS.NOT_APPROVED ? model.state : CONSTANTS.NOT_DELIVERED : CONSTANTS.NOT_DELIVERED
        model.projectmemberid = model._id ? model.projectmemberid : this.activeUser._id
        const valueCheckArray = ['worktypeid', 'breakhr', 'taskid', 'overtime50', 'overtime100']
        valueCheckArray.forEach(x => { if (!model[`${x}`]) model[`${x}`] = null })
        if (model.type === this.NON_WORKING) {
          model.customerid = model.projectid = model.customer_name = model.project_name = null
          model.date = model.start_date
        }
        // model.type = CONSTANTS.WORKING
        const url = this.MODULE
        this.$api.saveUpdateHandler(url, model)
          .then(() => {
            this.closeLogForm()
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'savedSuccess' })
            this.$eventBus.$emit('getAllHourLogs')

            // save customer & project for next new timer
            if (model._id) return
            const customer = this.listOfCustomers.find((customer) => customer._id === model.customerid)
            const project = this.listOfProjects.find((project) => project._id === model.projectid)
            localStorage.setItem(`${process.env.VUE_APP_STORE_NAME}_${this.userDetails.tenantname}_timerFormPreference`, JSON.stringify({ customer, project }))
          })
          .finally(() => {
            this.loading = false
          })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    isHourEditable (state) {
      if (!state) return false
      if (state === CONSTANTS.APPROVED || state === CONSTANTS.DELIVERED) return true
      else return false
    },
    // setInvoiceTo () {
    //   const hours = this.$formatter.cloneVariable(this.hourObj.hours)
    //   this.$set(this.hourObj, 'toinvoice', hours)
    // },
    closeLogForm () {
      this.$eventBus.$emit('closeForm')
      if (this.$refs && this.$refs.hourReference) this.$refs.hourReference.$refs.validateForm.reset()
    },
    formatTime (date) {
      return this.$formatter.formatDate(date, `${this.userDetails.dateformat} HH:mm`, 'HH:mm')
    },
    formatDate (date) {
      return this.$formatter.formatDate(date, `${this.userDetails.dateformat} HH:mm`, this.userDetails.dateformat)
    },
    loadCustomerData ({ searchterm } = {}) {
      return new Promise((resolve) => {
        this.customerSearchLoading = true
        const query = [{
          $match: {
            $and: [{
              $or: [
                { 'Data.type': 'customer' },
                { 'Data.type': 'Kunde' }
              ]
            }, {
              'Data.name': { $options: 'i', $regex: (searchterm || this.hourObj.customerSearch || '') }
            }]
          }
        }, {
          $project: { _id: 1, 'Data.name': 1 }
        }]
        // if (this.getTimerSettings.filteraccountsbyassignee) query[0].$match.$and.push({ $or: [{ 'Data.assigned_to': this.userDetails._id }, { 'Data.members._v': { $in: [this.userDetails._id] } }] })
        if (this.getTimerSettings.filteraccountsbyassignee) query[0].$match.$and.push({ $or: [{ 'Data.assigned_to._v': { $in: [this.userDetails._id] } }, { 'Data.assigned_to': this.userDetails._id }] })
        this.$api.execute('post', 'moduledata/Account/query', query)
          .then(({ data }) => {
            const list = data
            list.forEach(item => {
              item.name = item.data.name
            })
            this.listOfCustomers = list
            resolve(this.listOfCustomers)
          }).finally(() => {
            this.customerSearchLoading = false
          })
      })
    },
    loadProjectData (searchTerm) {
      return new Promise((resolve) => {
        this.projectSearchLoading = true
        // this.listOfProjects = []
        const query = [{
          $match: {
            $or: [{
              'Data.name': { $options: 'i', $regex: searchTerm || '' }
            }, {
              'Data.number': { $options: 'i', $regex: searchTerm || '' }
            }],
            $and: [
              { 'Data.status': { $ne: 'completed' } }
            ]
          }
        }, {
          $project: { _id: 1, 'Data.name': 1, 'Data.number': 1 }
        }]
        if (this.timerFormSettings && this.timerFormSettings.customer) query[0].$match.$and.push({ Relations: { $elemMatch: { _id: { $eq: this.hourObj.customerid || '' } } } })
        // if (this.getTimerSettings.filterprojectsbyassignee) query[0].$match.$and.push({ $or: [{ 'Data.assigned_to': this.userDetails._id }, { 'Data.members._v': { $in: [this.userDetails._id] } }] })
        if (this.getTimerSettings.filterprojectsbyassignee) query[0].$match.$and.push({ $or: [{ 'Data.assigned_to._v': { $in: [this.userDetails._id] } }, { 'Data.assigned_to': this.userDetails._id }, { 'Data.members._v': { $in: [this.userDetails._id] } }] })
        this.$api.execute('post', 'moduledata/Project/query', query)
          .then(({ data }) => {
            if (data && data.length) {
              const projectList = data.map(({ _id, data: itemdata }) => ({
                _id,
                projectName: itemdata.number ? `${itemdata.number} - ${itemdata.name}` : `${itemdata.name}`
              }))
              this.listOfProjects = projectList
              resolve(projectList)
            } else resolve([])
          }).finally(() => {
            this.projectSearchLoading = false
          })
      })
    },
    makeListEmpty (listName) {
      this[listName] = []
    },
    setHours (startTime, endTime) { // starttime & end time of selected
      if (startTime && endTime) {
        const hours = this.$moment(`${endTime}`, 'HH:mm').diff(this.$moment(`${startTime}`, 'HH:mm'), 'hours', true)
        if (!this.isTypeAbsence) this.hourObj.hours = (hours) ? this.$formatter.replaceDotWithComma(hours) : '0,00'
        if (this.getTimerSettings.setinvoiceonlybyadmin ? this.userDetails.isadmin : true) this.hourObj.toinvoice = (hours ? ((this.hourObj.toinvoice && this.$formatter.replaceCommaWithDot(this.hourObj.toinvoice) > 0) ? this.hourObj.toinvoice : this.$formatter.replaceDotWithComma(hours)) : '0,00')
        // set toinvoice value
        const toinvoice = (hours ? ((this.hourObj.toinvoice && this.$formatter.replaceCommaWithDot(this.hourObj.toinvoice) > 0) ? this.hourObj.toinvoice : this.$formatter.replaceDotWithComma(hours)) : '0,00')
        this.$set(this.hourObj, 'toinvoice', toinvoice)
      }
    },
    checkForAbsenceType () { // Check whether selected worktype in absence
      const result = this.worktypes.find(x => x._id === this.hourObj.worktypeid && x.isabsence)
      if (result) {
        this.setAllowedDates()
        this.hourObj.type = this.NON_WORKING
        this.isTypeAbsence = true
        this.canShowHours = false
        if (this.hourObj.start_date === this.hourObj.end_date) {
          this.canShowHours = true
        } else this.canShowHours = false
      } else {
        this.canShowHours = true
        this.isTypeAbsence = false
        this.hourObj.type = this.WORKING
      }
    },
    setAllowedDates () {
      this.hourObj.datePickerStart = this.hourObj.datePickerEnd = this.$moment(this.hourObj.date, this.userDetails.dateformat).format('YYYY-MM-DD')
    },
    async customerChangeHandler () {
      this.hourObj.projectid = null
      this.listOfProjects = []
      await this.loadProjectData()
    }
  },
  beforeDestroy () {
    this.$root.$off('taskProjectId')
    // this.$eventBus.$off('updateProjectsList')
  }
}
</script>
<style>
.max-width-500 {
  max-width: 500px
}
.max-width-600 {
  max-width: 600px
}
.max-width-650 {
  max-width: 650px
}
.bordered--table {
  width: 100%;
}
.bordered--table table, .bordered--table table thead tr, .bordered--table table tbody tr {
  border: 1px solid rgba(0,0,0,0.12);
}
.bordered--table table thead th, .bordered--table table tbody tr td {
  border-right: 1px solid rgba(0,0,0,0.12);
}
.so_task_row {
  background: #0095ff;
  color: white;
}
.so_task_row:hover {
  background:#0095ffbd !important;
  color: white;
}
</style>
